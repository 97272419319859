<template>
	<div>
		<div class="ft20 cl-main ftw600">员工业绩</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="员工">
						<a-input v-model="search.staff" placeholder="请输入员工姓名/手机号"></a-input>
					</a-form-item>

					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class="pd30 bg-w " style="min-height: 800px;">
				<div>
					<a-radio-group v-model="search.time_type" button-style="solid" @change="changeTimeType">
						<a-radio-button :value="1">
							本月业绩
						</a-radio-button>
						<a-radio-button :value="2">
							上月业绩
						</a-radio-button>
						<a-radio-button :value="3">
							本年度业绩
						</a-radio-button>
					</a-radio-group>
				</div>

				<div class="mt20 flex alcenter">
					<div class="ft12 ftw400 cl-main" v-if="search.time_type==1">本月员工总业绩：</div>
					<div class="ft12 ftw400 cl-main" v-if="search.time_type==2">上月员工总业绩：</div>
					<div class="ft12 ftw400 cl-main" v-if="search.time_type==3">本年度员工总业绩：</div>
					<div class="ft16 ftwblod cl-price">¥{{total_commission}}</div>
				</div>
				<div class="mt20">
					<div class="wxb-table-gray">
						<a-table rowKey="rank" :columns="columns" :pagination="pagination" @change="handleTableChange"
							:data-source="datas" :loading="loading">

							<div class="flex alcenter center" slot="rank" slot-scope="rank,record">
								{{rank}}
							</div>

							<div  slot="serviceman" slot-scope="serviceman,record">
								<div class="flex center" v-if="serviceman">
									{{serviceman.name}}
								</div>
								<div class="flex alcenter" v-else>-</div>
							</div>

							<div  slot="serviceman_mobile" slot-scope="record">
								<div class="flex center" v-if="record.serviceman">
									{{record.serviceman.mobile}}
								</div>
								<div class="flex alcenter" v-else>-</div>
							</div>

							<div class="flex center cl-theme" slot="commission" slot-scope="commission,record">
								¥{{commission}}
							</div>

							<template slot="action" slot-scope="record,index">
								<div class="flex center" >
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="getDetail(record)">
													<i class="iconfont ft14 iconsee"></i>
													<span class="ml10">查看详情</span>
												</a>
											</a-menu-item>

										</a-menu>
									</a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			</div>
		</div>

		<div v-if="showEmployeePerformanceDetailVisible">
			<employee-performance-detail :visible="showEmployeePerformanceDetailVisible" :staff_id="staff_id" @cancel="cancelShowEmployeePerformanceDetail"></employee-performance-detail>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../../common/mixin/list.js';
	import employeePerformanceDetail from './components/EmployeePerformance/modal/EmployeePerformanceDetail.vue';
	export default {
		mixins: [listMixin],
		components:{
			employeePerformanceDetail,
		},
		data() {
			return {
				loading: false,
				showEmployeePerformanceDetailVisible:false,
				total_commission:0,
				staff_id:0,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					staff:'',
					time_type:1,
				},
				columns: [
					{title: '序号',dataIndex: 'rank',align: 'center',scopedSlots: {customRender: 'rank'}},
					{title: '员工',dataIndex: 'serviceman',align: 'center',scopedSlots: {customRender: 'serviceman'}},
					{title: '员工手机号',key: 'serviceman_mobile',align: 'center',scopedSlots: {customRender: 'serviceman_mobile'}},
					{title: '核准业绩',dataIndex: 'commission',align: 'center',scopedSlots: {customRender: 'commission'}},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods: {
			getLists() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/getEmployeePerformanceList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					time_type:this.search.time_type,
					staff:this.search.staff,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.total_commission=res.total_commission;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			getDetail(record){
				this.staff_id=record.serviceman_id;
				this.showEmployeePerformanceDetailVisible=true;
			},
			cancelShowEmployeePerformanceDetail(){
				this.showEmployeePerformanceDetailVisible=false;
			},

			changeTimeType(){
				this.pagination.current=1;
				this.getLists();
			},

			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>

</style>
